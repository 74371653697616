footer {
    position: fixed;
    bottom: 5%;
    width: 100%;
  }
  
  .App {
    text-align: center;
  }
  
  .data-area-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly
  }
  
  .navbarButton {
    color: #fff !important;
    padding: .5rem 1rem !important;
  }
  
  .navbarStyle {
    padding: .5rem 1rem !important;
  }
  
  .table-responsive {
    max-height: 39rem !important;
  }
  
  .tableColumn {
    word-break: break-all
  }
  
  .todo-form {
    width: 60%;
  }
  
  .todo-list {
    width: 60%;
  }
  
  .todo-label {
    font-size: large;
    margin-right: 22%;
    margin-left: 3%;
  }
  
  .todo-view-btn {
    float: right;
  }
  
  .iconText {
    margin: 0 .5rem;
  }
  
  .profileButton {
    margin: .5rem .5rem;
  }
  