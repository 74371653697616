@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Titillium Web', sans-serif;
}

:root{
    --mainColor: #251F87;
    --mainColorLight: #5767aa;
    --textColor: #eee;
}
header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    color: var(--textColor);
    background-color: #251F87;
}
nav a{
    margin: 0 2rem;
    color: var(--textColor);
    text-decoration: none;
}
header .nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
}

@media only screen and (max-width: 1024px){
    header .nav-btn{
        visibility: visible;
        opacity: 1;
    }
    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        transform: translateY(-100vh);
    }
header .responsive_nav{
    transform: none;
}

}

/*footer css*/
.footer-row {
    display: flex;
    background-color: #251F87;
    color:white;
    margin-top: 0px !important;
  }
  
  .footer-column {
    flex: 1;
 /* Optional: Add spacing between columns */
  }

  ul {
    list-style-type: none;
  }
  
